import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { fetchCourseById, getCourseProgress } from '../course/courseSlice';
// import { fetchPageById } from './coursePageSlice';


// ----------------- Thunks -----------------------------


// ----------------- Reducers ---------------------------

const viewCourseAdapter = createEntityAdapter()


const initialState = {
  tocMenuIsClosed: false,

  currentCourseId:null,
  currentSectionId:null,
  currentPageId:null,
  nextAvailablePageId:null,

  dripContent: null,

  sections: {
    ids: [],
    entities: {}
  },
  pages: {
    ids: [],
    entities: {}
  },
  // pageModules:{
  //   ids: [],
  //   entities: {}
  // }
};

const courseViewSlice = createSlice({
  name: 'viewCourse',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleTocMenuIsClosed: (state) => {
      state.tocMenuIsClosed = !state.tocMenuIsClosed;
    },
    setCurrentPageId: (state, action) => {
      state.currentPageId = action.payload;
    },
    setActiveSectionId: (state, action) => {
      state.currentSectionId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        const course = action.payload
        const sections = [];
        const pages = [];
        let tempPageId = state.currentPageId || course.lastViewedPageId;

        const dripContent = course.settings.dripContent || {};

        state.dripContent = dripContent;

        let prevPageUnlocked = true;
        let nextAvailablePageId;

        course.toc.forEach((section)=>{
          const pageIds = [];
          const sectionPages = [];

          section.pages.forEach(page =>{
            let isLocked = false;
            if (dripContent.gatedAccess && page.id !== 'diploma') {
              if (prevPageUnlocked) {
                prevPageUnlocked = !page.isLocked && (page.isViewed || page.progress > 0 );
              }
              else isLocked = true;

            }

            if(!isLocked || !nextAvailablePageId) {
              nextAvailablePageId = page.id;
            }

            pageIds.push(page.id);
            sectionPages.push({...page, isLocked, sectionId:section.id});

            if(page.id === tempPageId && !isLocked) state.currentSectionId = section.id;
          });

          pages.push(...sectionPages);
          sections.push({...section, pages:pageIds});
        });

        state.currentCourseId = course.id;
        state.currentPageId = tempPageId;
        state.nextAvailablePageId = nextAvailablePageId;

        // state.sections.entities = sections;
        // state.pages.entities = pages;

        viewCourseAdapter.setAll(state.sections, sections);
        viewCourseAdapter.setAll(state.pages, pages);

      })
      .addCase(getCourseProgress.fulfilled, (state, action) => {

        const sectionsArr = [];
        const pagesArr = [];

        let prevPageUnlocked = true;
        let nextAvailablePageId;

        const progressData = action.payload.hasOwnProperty('toc') ? action.payload.toc : action.payload;

        progressData.forEach((sectionProgress) => {
          const original = state.sections.entities[sectionProgress.id];
          if (!original) return;

          const section = { ...original };
          section.progress = sectionProgress.progress;

          const isSectionLocked = section.isLocked;

          sectionProgress.pages.forEach((pageProgress) => {
            const originalPage = state.pages.entities[pageProgress.id];
            if (!originalPage) return;

            const page = { ...originalPage };
            page.progress = pageProgress.progress;

            let isLocked = false;

            if (state.dripContent.gatedAccess) {
              if (isSectionLocked || !prevPageUnlocked) {
                isLocked = true;
              } else {
                prevPageUnlocked = page.isViewed || page.progress > 0;
              }
            }

            if (!isLocked && !nextAvailablePageId) {
              nextAvailablePageId = page.id;
            }

            page.isLocked = isLocked;
            pagesArr.push(page);
          });

          sectionsArr.push(section);
        });

        viewCourseAdapter.upsertMany(state.sections, sectionsArr);
        viewCourseAdapter.upsertMany(state.pages, pagesArr);

        state.nextAvailablePageId = nextAvailablePageId;
      });
  }
})

export const { toggleTocMenuIsClosed, setCurrentPageId, reset, setActiveSectionId, updateTaskResult } = courseViewSlice.actions;

export default courseViewSlice.reducer;


// ----------------- Selectors --------------------------

export const {
  selectAll: selectCourseSections,
  selectById: selectCourseSectionById,
} = viewCourseAdapter.getSelectors((state) => state.viewCourse.sections);

export const {
  selectAll: selectCoursePages,
  selectById: selectCoursePageById,
} = viewCourseAdapter.getSelectors((state) => state.viewCourse.pages);

// export const {
//   selectAll: selectpageModules,
//   selectById: selectpageModulesById,
// } = viewCourseAdapter.getSelectors((state) => state.viewCourse.pageModules);

// export const selectCoursePageById = state => state.pages.entities

export const selectTocMenuIsClosed = state => state.viewCourse.tocMenuIsClosed;
export const selectCurrentPageId = state => state.viewCourse.currentPageId;
export const selectCurrentCourseId = state => state.viewCourse.currentCourseId;
export const selectCurrentSectionId = state => state.viewCourse.currentSectionId;
export const selectNextAvailablePageId = state => state.viewCourse.nextAvailablePageId;

const selectSectionId = (state, sectionId) => sectionId;
export const selectPagesBySection = createSelector(
  [selectCoursePages, selectSectionId],
  (pages, sectionId) => pages?.filter((item) => item.sectionId === sectionId)
);

// const selectPageId = (state, pageId) => {
//   return pageId || state.currentPageId;
// };
// export const selectSectionByPageId = createSelector(
//   [selectCourseSections, selectPageId],
//   (sections, pageId) =>
//     sections.find((section) => section.pages.includes(pageId))
// );

// export const selectSectionIdByPageId = createSelector(
//   [selectCourseSections, selectPageId],
//   (sections, pageId) =>{
//     const item = sections.find((section) => section.pages.includes(pageId))
//     return item?.id;
//   }
// );



